import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';
import { AppState } from '../../global/types';

const initialState = {
  salesProducts: {
    data: null,
    loading: false,
    error: null,
  },
};

const reducer = createReducer(initialState, {
  [actions.getSalesProducts.REQUEST]: (state) => {
    state.salesProducts.loading = true;
  },
  [actions.getSalesProducts.SUCCESS]: (state, action) => {
    state.salesProducts.data = action.payload;
    state.salesProducts.loading = false;
  },
  [actions.getSalesProducts.ERROR]: (state, action) => {
    state.salesProducts.error = action.payload;
    state.salesProducts.loading = false;
  },
});

const salesProductsData = (state: AppState) =>
  state.salesPageReducer.salesProducts.data;

const selectors = {
  salesProductsData,
};

export { selectors };
export default reducer;
