import * as Yup from 'yup';
import { SalesProductFormatedData } from './types';

export const formatSalesProductData = (product: SalesProductFormatedData) => {
  return {
    pharmacy_product: product.pharmacy_product,
    expiration_date: product.expiration_date,
    quantity: product.quantity,
    current_price: product.current_price,
    discount_price: product.discount_price,
    sku: product.sku,
    series: product.series,
    comment: product.comment,
  };
};

const salesProductSchemaCommon = {
  discount_price: Yup.string()
    .required('Поле не может быть пустым')
    .matches(/^[^0].*/, 'Цена не должна начинаться с "0"'),
  quantity: Yup.string().required('Поле не может быть пустым'),
  sku: Yup.string().required('Поле не может быть пустым'),
  pharmacy_product: Yup.string().required('Поле не может быть пустым'),
};
export const salesProductsSchemaCommon = Yup.object(salesProductSchemaCommon);
export const salesProductsSchema = Yup.array().of(
  Yup.object().shape(salesProductSchemaCommon)
);

export const formatSalesProductsData = (
  productsList: SalesProductFormatedData[]
) => {
  return productsList.map((product) => formatSalesProductData(product));
};

export const getProductsBySearchValue = (
  productsList: SalesProductFormatedData[],
  searchProductValue: string
): SalesProductFormatedData[] => {
  return productsList.filter((product) =>
    product.sku.toLowerCase().includes(searchProductValue.toLowerCase().trim())
  );
};
