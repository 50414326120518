import styled from 'styled-components';
import { baseTheme } from '../../../global/styles/theme';

export const CreateOrderFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  .field-container {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    .label {
      margin: 10px 0px;
    }

    input {
      width: 300px;
      color: ${baseTheme.colors.secondary};
      font-size: 16px;
      -webkit-appearance: none;
      border: 1px solid black;
      border-radius: 5px;
      padding: 6px;
      background-color: inherit;

      &:focus {
        outline: none;
      }

      &::placeholder {
        color: ${baseTheme.colors.tertiary};
      }
    }
  }

  .submit-btn {
    width: 200px;
    margin: 20px;
    font-weight: 400;
    font-size: 16px;
    padding: 8px 20px;
    border-radius: 8px;
    background-color: ${baseTheme.colors.focus};
    cursor: pointer;
    color: ${baseTheme.colors.white};
    border: none;
  }

  .error-field {
    position: absolute;
    top: 75px;
    color: ${baseTheme.colors.error};
    width: max-content;
    height: 20px;
  }

  .disabled {
    pointer-events: none;
    background: ${baseTheme.colors.disabled} !important;
    border: 2px solid ${baseTheme.colors.disabled};
  }
`;
