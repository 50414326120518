import React, { ChangeEvent, FormEventHandler } from 'react';
import { t } from 'i18next';
import { FormikErrors } from 'formik';
import { SalesProductFormatedData } from '../types';
import * as Styled from './NewProductCreate.styles';

interface OrderFormProps {
  values: {
    pharmacy_product: string;
    discount_price: string;
    current_price: string;
    expiration_date: string;
    quantity: string;
    sku: string;
    series: string;
    comment: string;
  };
  setFieldValue?: (field: string, value: string | number | boolean) => void;
  handleSubmit: FormEventHandler<HTMLFormElement>;
  handleChange: {
    (e: ChangeEvent<any>): void;
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void;
  };
  errors: FormikErrors<SalesProductFormatedData>;
}

const NewProductCreate = ({
  values,
  handleSubmit,
  setFieldValue,
  handleChange,
  errors,
}: OrderFormProps) => {
  return (
    <form onSubmit={handleSubmit}>
      <Styled.CreateOrderFormContainer>
        <div className="field-container">
          <span className="label">{t('SALESPAGE.TABLE.HEADER.PRODUCT')}</span>
          <input
            value={values.pharmacy_product}
            onChange={handleChange}
            name="pharmacy_product"
          />
          {errors?.pharmacy_product && (
            <div className="error-field">{errors?.pharmacy_product}</div>
          )}
        </div>
        <div className="field-container">
          <span className="label">
            {t('SALESPAGE.TABLE.HEADER.CURRENT_AMOUNT')}*
          </span>
          <input
            value={values.current_price}
            onChange={handleChange}
            name="current_price"
          />
          {errors?.current_price && (
            <div className="error-field">{errors?.current_price}</div>
          )}
        </div>
        <div className="field-container">
          <span className="label">
            {t('SALESPAGE.TABLE.HEADER.NEW_AMOUNT')}*
          </span>
          <input
            value={values.discount_price}
            onChange={handleChange}
            name="discount_price"
          />
          {errors?.discount_price && (
            <div className="error-field">{errors?.discount_price}</div>
          )}
        </div>
        <div className="field-container">
          <span className="label">
            {t('SALESPAGE.TABLE.HEADER.BEST_BEFORE')}
          </span>
          <input
            type="date"
            value={values.expiration_date}
            onChange={handleChange}
            name="expiration_date"
          />
        </div>
        <div className="field-container">
          <span className="label">{t('SALESPAGE.TABLE.HEADER.SERIES')}</span>
          <input value={values.series} onChange={handleChange} name="series" />
          {errors?.series && (
            <div className="error-field">{errors?.series}</div>
          )}
        </div>
        <div className="field-container">
          <span className="label">{t('SALESPAGE.TABLE.HEADER.COMMENT')}</span>
          <input
            value={values.comment}
            onChange={handleChange}
            name="comment"
          />
          {errors?.comment && (
            <div className="error-field">{errors?.comment}</div>
          )}
        </div>
        <button
          type="submit"
          className={`submit-btn ${
            Object.keys(errors).length > 0 && 'disabled'
          }`}
        >
          {t('SALESPAGE.TABLE.HEADER.CREATE')}
        </button>
      </Styled.CreateOrderFormContainer>
    </form>
  );
};

export default NewProductCreate;
