import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import * as Styled from './SalesPage.styles';
import * as actions from './actions';
import { selectors } from './reducer';
import { SalesFormatedPharmacy, SalesProductsTabOption } from './types';
import { EditService } from '../EditPage/service';
import { OrdersService } from '../OrdersPage/service';

import SearchBar from '../../components/SearchBar';
import { getFromLocalStorage } from '../../global/helpers';
import { selectors as userSelectors } from '../LoginPage/reducer';
import { UserRole } from '../OrdersPage/types';
import { salesProductsSchemaCommon } from './helpers';
import ModalWindow from '../../components/ModalWindow';
import SalesProductsTable from './SalesProductsTable/SalesProductsTable';
import NewProductCreate from './NewProductCreate/NewProductCreate';
import BestBeforeProductsTable from './BestBeforeProductsTable/BestBeforeProductsTable';

function getDateString(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

const getEncryptedCity = (firstParam: string) => {
  if (firstParam === 'Астана') {
    return '%D0%90%D1%81%D1%82%D0%B0%D0%BD%D0%B0';
  }
  if (firstParam === 'Шымкент') {
    return '%D0%A8%D1%8B%D0%BC%D0%BA%D0%B5%D0%BD%D1%82';
  }
  if (firstParam === 'Караганда') {
    return '%D0%9A%D0%B0%D1%80%D0%B0%D0%B3%D0%B0%D0%BD%D0%B4%D0%B0';
  }
  return '%D0%90%D0%BB%D0%BC%D0%B0%D1%82%D1%8B';
};

const SalesPage = () => {
  const { t } = useTranslation();
  const salesProductsData = useSelector(selectors.salesProductsData);
  const [searchProductList, setSearchProductList] = useState<any[]>([]);
  const dispatch = useDispatch();
  const userData = useSelector(userSelectors.userData);
  const networkCode =
    getFromLocalStorage('networkData')?.code || userData?.sourceCode;
  const role = getFromLocalStorage('role');
  const [currentPharmacy, setCurrentPharmacy] =
    useState<null | SalesFormatedPharmacy>(null);
  const [currentTab, setCurrentTab] = useState(
    SalesProductsTabOption.salesProducts
  );
  const [searchProductValue, setSearchProductValue] = useState<string>('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const [minPrices, setMinPrices] = useState<any[]>([]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (
        searchProductValue.length >= 3 &&
        currentTab === SalesProductsTabOption.salesProducts
      ) {
        EditService.getProducts(searchProductValue).then((res) => {
          OrdersService.getProductsBySkus(
            res.items.map((f: any) => f.sku)
          ).then((resp) => setSearchProductList(resp.result));
        });
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [searchProductValue, currentTab]);

  useEffect(() => {
    if (
      (role === UserRole.PHARMACIST && networkCode) ||
      ((role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER) &&
        currentPharmacy?.code)
    ) {
      dispatch(
        actions.getSalesProducts.request({
          networkCode:
            role === UserRole.MANAGER || role === UserRole.PHARMACY_MANAGER
              ? currentPharmacy?.code
              : networkCode,
        })
      );
    }
  }, [networkCode, currentPharmacy, role]);

  useEffect(() => {
    if (salesProductsData?.length === 0) return;
    fetch(
      `https://prod-backoffice.daribar.com/api/v1/prices?city=${getEncryptedCity(
        getFromLocalStorage('city') || ''
      )}`,
      {
        method: 'POST',
        body: JSON.stringify(
          salesProductsData?.map((f) => f.sku.replace(/_(.*)/, ''))
        ),
        headers: { 'Content-Type': 'text/plain' },
      }
    ).then(async (res) => {
      const data = await res.json();
      setMinPrices(data.result);
      console.log(data);
    });
  }, [salesProductsData]);

  const onCreateProductHandler = ({
    // eslint-disable-next-line
    pharmacy_product,
    // eslint-disable-next-line
    current_price,
    // eslint-disable-next-line
    discount_price,
    // eslint-disable-next-line
    expiration_date,
    quantity,
    sku,
    series,
    comment,
  }: {
    pharmacy_product: string;
    current_price: string;
    discount_price: string;
    quantity: string;
    expiration_date: string;
    sku: string;
    series: string;
    comment: string;
  }) => {
    const createdNewOrder = {
      // eslint-disable-next-line
      pharmacy_product,
      current_price: parseInt(current_price, 10),
      discount_price: parseInt(discount_price, 10),
      quantity: parseInt(quantity, 10),
      sku,
      expiration_date: getDateString(new Date(expiration_date)),
      series,
      comment,
    };

    fetch(
      `https://prod-backoffice.daribar.com/api/v1/prices?city=${getEncryptedCity(
        getFromLocalStorage('city') || ''
      )}`,
      {
        method: 'POST',
        body: JSON.stringify([sku]),
        headers: { 'Content-Type': 'text/plain' },
      }
    )
      .then(async (res) => {
        const data = await res.json();
        dispatch(
          actions.createNewSaleOrder.request({
            createdNewOrder: {
              ...createdNewOrder,
              avg_price:
                data.result?.[0]?.avg_customer_price ||
                createdNewOrder.current_price,
            },
            postEffect: () => {
              dispatch(
                actions.getSalesProducts.request({
                  networkCode:
                    role === UserRole.MANAGER ||
                    role === UserRole.PHARMACY_MANAGER
                      ? currentPharmacy?.code
                      : networkCode,
                })
              );
              setIsCreateModalOpen(!isCreateModalOpen);
              setSelected({});
            },
          })
        );
      })
      .catch((e) => {
        console.log(e);
        dispatch(
          actions.createNewSaleOrder.request({
            createdNewOrder,
            postEffect: () => {
              dispatch(
                actions.getSalesProducts.request({
                  networkCode:
                    role === UserRole.MANAGER ||
                    role === UserRole.PHARMACY_MANAGER
                      ? currentPharmacy?.code
                      : networkCode,
                })
              );
              setIsCreateModalOpen(!isCreateModalOpen);
              setSelected({});
            },
          })
        );
      });
  };

  const tableHeader = (
    <thead>
      <Styled.SalesTableHeaderRow>
        <th className="header-row header-row-lg">
          {t('SALESPAGE.TABLE.HEADER.PRODUCT')}
        </th>
        {currentTab === SalesProductsTabOption.bestBeforeProducts && (
          <>
            <th className="header-row header-row-sm">
              {t('SALESPAGE.TABLE.HEADER.MIN_AMOUNT')}
            </th>
            <th className="header-row header-row-sm">
              {t('SALESPAGE.TABLE.HEADER.AMOUNT')}
            </th>
            <th className="header-row header-row-sm">
              {t('SALESPAGE.TABLE.HEADER.BEST_BEFORE')}
            </th>
            <th className="header-row header-row-sm">
              {t('SALESPAGE.TABLE.HEADER.SERIES')}
            </th>
            <th className="header-row header-row-sm">
              {t('SALESPAGE.TABLE.HEADER.NEW_AMOUNT')}
            </th>
            <th className="header-row header-row-sm">
              {t('SALESPAGE.TABLE.HEADER.COMMENT')}
            </th>
          </>
        )}
        <th className="header-row header-row-sm">
          {t('SALESPAGE.TABLE.HEADER.ACTION')}
        </th>
      </Styled.SalesTableHeaderRow>
    </thead>
  );
  return (
    <>
      <Styled.SalesPageContainer>
        <div className="products-title"> {t('SALESPAGE.TITLE')}</div>
        <Styled.SalesPageTabsContainer>
          <div
            className={`tab-item ${
              currentTab === SalesProductsTabOption.salesProducts &&
              'type-active'
            }`}
            onClick={() => setCurrentTab(SalesProductsTabOption.salesProducts)}
          >
            {t('SALESPAGE.TABS.SALES_PRODUCTS')}
          </div>
          <div
            className={`tab-item ${
              currentTab === SalesProductsTabOption.bestBeforeProducts &&
              'type-active'
            }`}
            onClick={() =>
              setCurrentTab(SalesProductsTabOption.bestBeforeProducts)
            }
          >
            {t('SALESPAGE.TABS.BEST_BEFORE')}
          </div>
        </Styled.SalesPageTabsContainer>

        <Styled.SalesPageTableContainer>
          {currentTab === SalesProductsTabOption.salesProducts && (
            <div className="search-container">
              <div className="serch-field-container">
                <SearchBar
                  value={searchProductValue}
                  placeholder="Поиск товаров"
                  setValue={(value) => {
                    setSearchProductValue(value);
                  }}
                  onSearchClick={() => false}
                />
              </div>
            </div>
          )}
          <Styled.SalesTableContainer>
            {tableHeader}
            <Styled.SalesTable>
              {currentTab === SalesProductsTabOption.salesProducts &&
                searchProductList
                  ?.slice()
                  ?.sort((a, b) => a.sku.localeCompare(b.sku))
                  .map((el) => (
                    <BestBeforeProductsTable
                      el={el}
                      key={el.sku}
                      data={el}
                      setIsModalOpen={() => {
                        setIsCreateModalOpen(true);
                        setSelected(el);
                      }}
                    />
                  ))}
              {currentTab === SalesProductsTabOption.bestBeforeProducts &&
                salesProductsData
                  ?.slice()
                  ?.sort((a, b) => a.sku.localeCompare(b.sku))
                  .map((el) => (
                    <SalesProductsTable
                      el={{
                        ...el,
                        minPrice: minPrices.find((f) => el.sku.includes(f.sku))
                          ?.min_customer_price,
                      }}
                      key={el.sku}
                    />
                  ))}
            </Styled.SalesTable>
          </Styled.SalesTableContainer>
          {searchProductValue.length >= 3 &&
            searchProductList?.length === 0 &&
            currentTab === SalesProductsTabOption.salesProducts && (
              <div className="empty-search-result-container">
                <p className="empty-search-result-text">
                  {t('SALESPAGE.NOT_FOUND_PRODUCTS')}
                </p>
              </div>
            )}
        </Styled.SalesPageTableContainer>
        {isCreateModalOpen && (
          <ModalWindow
            onClose={() => {
              setIsCreateModalOpen(!isCreateModalOpen);
              setSelected({});
            }}
          >
            <Formik
              initialValues={{
                pharmacy_product: (selected?.name as string) || '',
                discount_price: '0',
                current_price: (selected?.price.toString() as string) || '0',
                quantity: '0',
                sku: (selected?.sku as string) || '',
                expiration_date: '',
                series: '',
                comment: '',
              }}
              validationSchema={salesProductsSchemaCommon}
              onSubmit={onCreateProductHandler}
            >
              {({ values, handleSubmit, handleChange, errors }) => (
                <NewProductCreate
                  values={values}
                  handleSubmit={handleSubmit}
                  handleChange={handleChange}
                  errors={errors}
                />
              )}
            </Formik>
          </ModalWindow>
        )}
      </Styled.SalesPageContainer>
      {/* )} */}
    </>
  );
};

export default SalesPage;
