import { useTranslation } from 'react-i18next';
import { CircularProgress } from '@mui/material';
import React, { MouseEvent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import * as Styled from './OrdersTableRow.styles';
import {
  DeliveryType,
  OrderTableData,
  PaymentType,
  UserRole,
} from '../../../../types';
import OrderStatusBlock from '../../../../../../components/OrderStatusBlock';
import { StatusRunTimer } from '../../../../../OrderPage/types';
import {
  convertSeconds,
  getFromLocalStorage,
} from '../../../../../../global/helpers';
import success from '../../../../../../global/media/Vector (2).svg';
import CustomTextarea from '../../../../../../components/CustomTextarea';
import editIcon from '../../../../../../global/media/edit-pencil.svg';
import saveIcon from '../../../../../../global/media/save-discet-icon.svg';
import copyIcon from '../../../../../../global/media/copy-icon.png';
import Notification from '../../../../../../components/NotificationsList/components/Notification';
import { StatusType } from '../../../../../../global/types';

interface OrdersTableRowProps {
  data: OrderTableData;
  dataOrders?: OrderTableData[] | null;
  onRowClick: (data: OrderTableData) => void;
  dataByTab?: OrderTableData[] | null;
}

const OrdersTableRow = ({
  data,
  onRowClick,
  dataOrders,
  dataByTab,
}: OrdersTableRowProps) => {
  const { t } = useTranslation();
  const userRole = getFromLocalStorage('role');
  const [operatorComment, setOperatorComment] = useState<string>('');
  const [directorComment, setDirectorComment] = useState<string>('');
  const [editCommentActive, setEditCommentActive] = useState<boolean>(false);
  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(false);
  const [operatorErrorTooltip, setOperatorErrorTooltip] =
    useState<boolean>(false);
  const [directorErrorTooltip, setDirectorErrorTooltip] =
    useState<boolean>(false);

  useEffect(() => {
    if (editCommentActive) {
      userRole === UserRole.OPERATOR || userRole === UserRole.HEAD_OPERATOR
        ? setDirectorErrorTooltip(true)
        : setOperatorErrorTooltip(true);
    } else {
      setDirectorErrorTooltip(false);
      setOperatorErrorTooltip(false);
    }
  }, [userRole, editCommentActive]);

  const status =
    userRole === UserRole.OPERATOR ||
    userRole === UserRole.ADMIN ||
    userRole === UserRole.HEAD_OPERATOR
      ? data.status
      : data.statusPharmacy;
  const orderTimer =
    userRole === UserRole.OPERATOR ||
    userRole === UserRole.ADMIN ||
    userRole === UserRole.HEAD_OPERATOR
      ? data.timer?.remainTimer
      : data.pharmacyTimer?.remainTimer;
  const orderStatusTimer =
    userRole === UserRole.OPERATOR ||
    userRole === UserRole.ADMIN ||
    userRole === UserRole.HEAD_OPERATOR
      ? data.timer?.statusTimer
      : data.pharmacyTimer?.statusTimer;
  const [seconds, setSeconds] = useState<number>(orderTimer || 1200);
  const [secondsPharmacy, setSecondsPharmacy] = useState<number>(
    data.pharmacyTimer?.remainTimer || 1200
  );
  const [value, setValue] = useState<string>('');
  const [valuePharmacy, setValuePharmacy] = useState<string>('');
  const getTimer = (seconds: number, pharmacy?: boolean) => {
    const timer = !pharmacy ? orderTimer : data.pharmacyTimer?.remainTimer;
    if (
      Math.abs(seconds) / 3600 > 48 ||
      status === StatusType.CANCELED ||
      status === StatusType.COMPLETED
    )
      return null;
    const getTimerColor = () => {
      if (seconds > 240 && timer && timer > 0) return 'timer-green';
      if (seconds <= 240 && timer && timer > 0) return 'timer-orange';
      if (seconds <= 0 || (timer && timer <= 0)) return 'timer-red';
    };
    return (
      // eslint-disable-next-line
      <>
        {!pharmacy ? (
          <div className={`order-timer ${getTimerColor()}`}>
            {!value && <CircularProgress size={10} />}
            {value && timer && timer <= 0 ? `-${value}` : value}
          </div>
        ) : (
          <div className={`order-timer ${getTimerColor()}`}>
            {!valuePharmacy && <CircularProgress size={10} />}
            {valuePharmacy && timer && timer <= 0
              ? `-${valuePharmacy}`
              : valuePharmacy}
          </div>
        )}
      </>
    );
  };
  const getErrorStatus = () => {
    if (data && (data.paymentStatus || data.deliveryPaymentStatus)) {
      return (
        data.paymentStatus === 'refund_failed' ||
        data.paymentStatus === 'failed' ||
        data.paymentStatus === 'kaspi_not_found' ||
        data.deliveryPaymentStatus === 'refund_failed' ||
        data.deliveryPaymentStatus === 'failed' ||
        data.deliveryPaymentStatus === 'kaspi_not_found'
      );
    }

    return false;
  };

  useEffect(() => {
    if (orderTimer) {
      setSeconds(orderTimer);
    }
    if (data.pharmacyTimer?.remainTimer) {
      setSecondsPharmacy(data.pharmacyTimer?.remainTimer);
    }
  }, [orderTimer, data.pharmacyTimer?.remainTimer]);

  useEffect(() => {
    let timer: any = null;
    if (seconds && orderStatusTimer === StatusRunTimer.RUNNING) {
      timer = setInterval(() => {
        setSeconds(seconds - 1);
        setValue(convertSeconds(Math.abs(seconds)));
      }, 1000);
    } else {
      setValue(convertSeconds(Math.abs(seconds)));
    }
    return () => clearInterval(timer);
  }, [seconds, orderTimer]);

  useEffect(() => {
    let timer: any = null;
    if (
      secondsPharmacy &&
      data.pharmacyTimer?.statusTimer === StatusRunTimer.RUNNING
    ) {
      timer = setInterval(() => {
        setSecondsPharmacy(secondsPharmacy - 1);
        setValuePharmacy(convertSeconds(Math.abs(secondsPharmacy)));
      }, 1000);
    } else {
      setValuePharmacy(convertSeconds(Math.abs(secondsPharmacy)));
    }
    return () => clearInterval(timer);
  }, [secondsPharmacy, data.pharmacyTimer?.remainTimer]);

  const handleSaveComment = () => {
    alert(
      `operator comment - ${operatorComment}, director comment - ${directorComment}`
    );
    setEditCommentActive(false);
  };

  const deliveryTypeTitlesStrategy = {
    [DeliveryType.DELIVERY]: t(
      'OrdersPage.OrdersTable.DELIVERY_STATUS.DELIVERY'
    ),
    [DeliveryType.SELF]: t('OrdersPage.OrdersTable.DELIVERY_STATUS.SELF'),
  };

  const paymentTypeTitlesStrategy = {
    [PaymentType.IN_PLACE]: t('OrdersPage.OrdersTable.PAYMENT_STATUS.SOURCE'),
    [PaymentType.KASPI_PAY]: t('OrdersPage.OrdersTable.PAYMENT_STATUS.DARIBAR'),
    [PaymentType.INTERPAY]: t('OrdersPage.OrdersTable.PAYMENT_STATUS.INTERPAY'),
  };

  const dataAlign =
    userRole === UserRole.ADMIN ||
    userRole === UserRole.OPERATOR ||
    userRole === UserRole.HEAD_OPERATOR
      ? 'vertical-align-top'
      : null;

  const tableCellCanceled =
    status === 'canceled' &&
    (userRole === UserRole.ADMIN ||
      userRole === UserRole.OPERATOR ||
      userRole === UserRole.HEAD_OPERATOR)
      ? 'table-cell-canceled'
      : null;

  const handleRowClick = (e: MouseEvent<HTMLTableRowElement>) => {
    const targetImg = e.target as HTMLElement;
    if (targetImg.tagName !== 'IMG') {
      onRowClick(data);
    }
  };

  const handleCopyText = (text: string) => {
    navigator.clipboard.writeText(text);
    setIsBannerVisible(true);
  };

  useEffect(() => {
    if (isBannerVisible) {
      const timeOut = setTimeout(() => {
        setIsBannerVisible(false);
      }, 500);

      return () => clearTimeout(timeOut);
    }
  }, [isBannerVisible]);

  const isDeliveryKaspi =
    data.deliveryType === DeliveryType.DELIVERY &&
    data.paymentType === PaymentType.KASPI_PAY;
  const isSelfKaspi =
    data.deliveryType === DeliveryType.SELF &&
    data.paymentType === PaymentType.KASPI_PAY;

  // eslint-disable-next-line
  const rowId = isDeliveryKaspi
    ? 'delivery_kaspi'
    : isSelfKaspi
    ? 'self_kaspi'
    : 'self';

  return (
    <>
      <Styled.OrdersTableRow
        id={rowId}
        onClick={(e) => {
          handleRowClick(e);
        }}
      >
        <td
          className={`table-cell table-cell__order-timer ${dataAlign} ${tableCellCanceled}`}
        >
          {orderTimer && getTimer(seconds)}
          {(userRole === UserRole.ADMIN ||
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.HEAD_OPERATOR) &&
            data.pharmacyTimer &&
            data.statusPharmacy &&
            !data.closed && (
              <>
                <div className="in-pharmacy">
                  {t('OrdersPage.OrdersTable.IN_PHARMACY_STATUS')}
                </div>
                {orderTimer && getTimer(secondsPharmacy, true)}
              </>
            )}
        </td>
        <td
          className={`table-cell table-cell__order-number ${dataAlign} ${tableCellCanceled}`}
        >
          <div className="orderNumber-block">
            <div className={getErrorStatus() ? 'item-yellow' : ''}>
              {' '}
              {data.orderNumber}
            </div>
            {(userRole === UserRole.ADMIN ||
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <div onClick={() => handleCopyText(data.orderNumber)}>
                {' '}
                <img src={copyIcon} alt="copy icon" />
              </div>
            )}
          </div>
        </td>
        {!isMobile && (
          <>
            <td
              className={`table-cell table-cell__created ${dataAlign} ${tableCellCanceled}`}
            >
              {data.createdAt}
            </td>
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <td className={`table-cell ${dataAlign} ${tableCellCanceled}`}>
                {data.userSource}
              </td>
            )}
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <td className={`table-cell ${dataAlign} ${tableCellCanceled}`}>
                <div className="phoneNumber-block">
                  <div> {data.phone}</div>
                  <div onClick={() => handleCopyText(data.phone)}>
                    {' '}
                    <img src={copyIcon} alt="cope icon" />
                  </div>
                </div>
              </td>
            )}
            {(userRole === UserRole.ADMIN ||
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.HEAD_OPERATOR ||
              userRole === UserRole.PHARMACY_MANAGER ||
              userRole === UserRole.MANAGER) && (
              <td
                className={`table-cell ${
                  (userRole === UserRole.MANAGER ||
                    userRole === UserRole.PHARMACY_MANAGER) &&
                  'table-cell__source'
                } ${dataAlign}`}
              >
                {data.sourceName}
              </td>
            )}
            {(userRole === UserRole.OPERATOR ||
              userRole === UserRole.ADMIN ||
              userRole === UserRole.HEAD_OPERATOR) && (
              <td className={`table-cell ${dataAlign} ${tableCellCanceled}`}>
                {data.collector.phone ? (
                  <>
                    {data.collector.name} <br />+{data.collector.phone}
                  </>
                ) : (
                  '-'
                )}
              </td>
            )}
            <td className={`table-cell ${dataAlign} ${tableCellCanceled}`}>
              {deliveryTypeTitlesStrategy[data.deliveryType]}
            </td>
          </>
        )}
        {(userRole === UserRole.OPERATOR ||
          userRole === UserRole.ADMIN ||
          userRole === UserRole.HEAD_OPERATOR) && (
          <td className={`table-cell ${dataAlign} ${tableCellCanceled}`}>
            <OrderStatusBlock
              status={data.statusDelivery}
              statusName={data.statusNameDelivery}
              statusColor={data.statusColorDelivery}
              blink={
                (data.status === 'in_delivery' &&
                  data.statusDelivery === 'delivered_finish') ||
                (data.status === 'in_delivery' &&
                  data.statusDelivery === 'returning')
              }
            />
          </td>
        )}
        {!isMobile && (
          <td className={`table-cell ${dataAlign} ${tableCellCanceled}`}>
            <OrderStatusBlock
              status={
                userRole === UserRole.ADMIN ||
                userRole === UserRole.OPERATOR ||
                userRole === UserRole.HEAD_OPERATOR
                  ? data.status
                  : data.statusPharmacy
              }
              statusName={
                userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR
                  ? data.statusName
                  : data.statusNamePharmacy
              }
              statusColor={
                userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR
                  ? data.statusColor
                  : data.statusColorPharmacy
              }
            />
            {(userRole === UserRole.ADMIN ||
              userRole === UserRole.OPERATOR ||
              userRole === UserRole.HEAD_OPERATOR) &&
              data.statusPharmacy &&
              !data.closed && (
                <>
                  <div className="in-pharmacy">
                    {t('OrdersPage.OrdersTable.IN_PHARMACY_STATUS')}
                  </div>
                  <OrderStatusBlock
                    status={data.statusPharmacy}
                    statusName={data.statusNamePharmacy}
                    statusColor={data.statusColorPharmacy}
                  />
                </>
              )}
          </td>
        )}
        {(userRole === UserRole.PHARMACIST ||
          userRole === UserRole.MANAGER ||
          userRole === UserRole.PHARMACY_MANAGER) && (
          <td
            className={`table-cell table-cell__payment ${dataAlign} ${tableCellCanceled}`}
          >
            {paymentTypeTitlesStrategy[data.paymentType]}
          </td>
        )}
        <td
          className={`table-cell table-cell__payment-status ${dataAlign} ${tableCellCanceled}`}
        >
          {data.paymentType === PaymentType.IN_PLACE && '-'}
          {data.paymentType !== PaymentType.IN_PLACE &&
            (data.paid ? (
              <div className="payment-true-block">
                <div>
                  <img className="success" src={success} alt="success" />
                  {t('OrdersPage.OrdersTable.PAYMENT.TRUE')}
                </div>
                <div className="was_paid_at">{data.wasPaidAt}</div>
              </div>
            ) : (
              t('OrdersPage.OrdersTable.PAYMENT.FALSE')
            ))}
        </td>
        {!isMobile && (
          <>
            <td
              className={`table-cell table-cell__amount ${dataAlign} ${tableCellCanceled}
            `}
            >
              <div
                className={
                  (userRole === UserRole.OPERATOR ||
                    userRole === UserRole.ADMIN ||
                    userRole === UserRole.HEAD_OPERATOR) &&
                  data.promoCode &&
                  data.promoCode.discount_target !== 'delivery'
                    ? 'table-cell__amount-discount'
                    : ''
                }
              >
                {' '}
                {data.amount}
              </div>

              {(userRole === UserRole.OPERATOR ||
                userRole === UserRole.ADMIN ||
                userRole === UserRole.HEAD_OPERATOR) &&
                data.promoCode &&
                data.promoCode.discount_target !== 'delivery' && (
                  <>
                    <div>
                      - {data.promoCode.value}{' '}
                      {data.promoCode?.discount_type === 'money' ? '₸' : '%'}
                    </div>

                    <div className="table-cell__amount-accent">
                      {data.amountWithDiscount}
                    </div>
                  </>
                )}
            </td>
            {data?.source.includes('food') &&
              dataByTab?.every((order: OrderTableData) =>
                order.source.includes('food')
              ) && (
                <td className="table-cell">
                  <div className="table-cell__comment">{data.comment}</div>
                </td>
              )}
          </>
        )}
      </Styled.OrdersTableRow>
      {status === StatusType.CANCELED &&
        (userRole === UserRole.OPERATOR ||
          userRole === UserRole.ADMIN ||
          userRole === UserRole.HEAD_OPERATOR) && (
          <>
            <Styled.OrderCancelTableRow>
              <div className="comment-area">
                {operatorErrorTooltip && (
                  <div className="error-tooltip">
                    {t('OrdersPage.OrdersTable.ERROR_TOOLTIP')}
                  </div>
                )}
                <CustomTextarea
                  onChange={(e) => {
                    setOperatorComment(e);
                  }}
                  value={operatorComment}
                  placeholder={t(
                    'OrdersPage.OrdersTable.CANCELED_AREA_PLACEHOLDER.OPERATOR_COMMENT'
                  )}
                  disabled={!editCommentActive || operatorErrorTooltip}
                />
              </div>
              <div className="comment-area">
                {directorErrorTooltip && (
                  <div className="error-tooltip">
                    {t('OrdersPage.OrdersTable.ERROR_TOOLTIP')}
                  </div>
                )}
                <CustomTextarea
                  onChange={(e) => {
                    setDirectorComment(e);
                  }}
                  value={directorComment}
                  placeholder={t(
                    'OrdersPage.OrdersTable.CANCELED_AREA_PLACEHOLDER.DIRECTOR_COMMENT'
                  )}
                  disabled={!editCommentActive || directorErrorTooltip}
                />
                <Styled.IconsContainer>
                  {!editCommentActive ? (
                    <div onClick={() => setEditCommentActive(true)}>
                      <img src={editIcon} alt="edit icon" />
                    </div>
                  ) : (
                    <div onClick={handleSaveComment}>
                      <img src={saveIcon} alt="save icon" />
                    </div>
                  )}
                </Styled.IconsContainer>
              </div>
            </Styled.OrderCancelTableRow>
            <Styled.OrderUserCancelMassage>
              <div className="user_cancel_msg">
                {t(
                  'OrdersPage.OrdersTable.CANCELED_AREA_PLACEHOLDER.USER_COMMENT'
                )}
              </div>
              <div>{data.userCancelMessage}</div>
            </Styled.OrderUserCancelMassage>
          </>
        )}
      {isBannerVisible && (
        <Styled.NotificationWrapper>
          <Notification
            onClose={() => setIsBannerVisible(false)}
            id={0}
            type="success"
            content={t('OrdersPage.COPY_SUCCESS')}
            canBeClosed
          />
        </Styled.NotificationWrapper>
      )}
    </>
  );
};

export default OrdersTableRow;
