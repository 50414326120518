import styled from 'styled-components';
import { baseTheme } from '../../global/styles/theme';

export const OrderContainer = styled('div')`
  display: flex;
  width: 1500px;
  margin: auto auto 20px auto;

  input[type='file'] {
    opacity: 0;
    z-index: -1;
    position: absolute;
  }

  input[type='file']:focus + label {
    outline: 2px solid;
  }

  @media only screen and (max-width: 550px) {
    width: 100%;
    margin: 0;
    padding: 0 24px;
    flex-direction: column;
  }
`;

export const HistoryChangesContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 36px 0 0;
  font-weight: 500;
  color: ${baseTheme.colors.mainLightBlue};
  cursor: pointer;
`;

export const OrderDetailContainer = styled('div')`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 5% 20px 18px;

  .subtitle {
    cursor: pointer;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
  }
  .list {
    list-style: none;
  }
  .flex-container {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .arrow {
    width: 10px;
    transform: rotate(90deg);
  }
  .arrow-active {
    width: 10px;
    transform: rotate(270deg);
  }

  .edit-title {
    color: ${baseTheme.colors.mainBlue};
    font-weight: 700;
    font-size: 24px;
  }

  .cancel-order-container {
    display: flex;
    justify-content: space-between;

    .cancel-btn {
      max-height: 50px;
      margin-right: 90px;
      cursor: pointer;
      font-weight: 500;
      font-size: 18px;
      padding: 11px 25px;
      color: ${baseTheme.colors.error};
      border-radius: 8px;
      border: 2px solid ${baseTheme.colors.error};
    }

    .cancel-btn-disabled {
      pointer-events: none;
      color: ${baseTheme.colors.disabled};
      border: 2px solid ${baseTheme.colors.disabled};
    }
  }
`;

export const OrderMainContainer = styled('div')`
  width: 100%;
  margin-top: 24px;
  justify-content: center;
`;
