import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import * as Styled from './InfoReportByDay.styles';
import { getFromLocalStorage } from '../../global/helpers';
import { UserRole } from '../../containers/OrdersPage/types';
import {
  OrdersReportResponse,
  SourcesReportResponse,
} from '../../containers/ReportPage/types';
import bottomArrow from '../../global/media/bottom-arrow.svg';

interface InfoParam {
  day: string;
  amountsInPlace: string;
  amountsOnline: string;
  ordersInPlace: SourcesReportResponse[];
  ordersOnline: SourcesReportResponse[];
  webDesktop?: SourcesReportResponse[];
  webMobile?: SourcesReportResponse[];
  appAndroid?: SourcesReportResponse[];
  appIOS?: SourcesReportResponse[];
  operator?: SourcesReportResponse[];
}

const InfoReportByDay = ({
  day,
  amountsInPlace,
  amountsOnline,
  ordersInPlace,
  ordersOnline,
  webMobile,
  webDesktop,
  appAndroid,
  appIOS,
  operator,
}: InfoParam) => {
  const { t } = useTranslation();
  const userRole = getFromLocalStorage('role');
  const [activeIndexOnline, setActiveIndexOnline] = useState<number | null>(
    null
  );
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const getCountByPaymentPlace = (data: SourcesReportResponse[]) => {
    return data?.reduce((accum, source) => {
      return accum + source.orders.length;
    }, 0);
  };

  const getPriceByCity = (data: SourcesReportResponse[], city: string) => {
    return data
      ?.filter((el) => el.source_city === city)
      ?.reduce((accum, source) => {
        return accum + source.total;
      }, 0);
  };

  const getPriceByCityAndPayment = (data: SourcesReportResponse[]) => {
    return data?.reduce((accum, source) => {
      return accum + source.total;
    }, 0);
  };

  const getCityOrdersByCity = (data: SourcesReportResponse[], city: string) => {
    return data?.filter((el: any) => el.source_city === city);
  };

  const userSources = [
    {
      title: t('OrdersPage.WEB_DESKTOP'),
      count: getCountByPaymentPlace(webDesktop || []),
      visible: Boolean(getCountByPaymentPlace(webDesktop || [])),
    },
    {
      title: t('OrdersPage.WEB_MOBILE'),
      count: getCountByPaymentPlace(webMobile || []),
      visible: Boolean(getCountByPaymentPlace(webMobile || [])),
    },

    {
      title: t('OrdersPage.APP_ANDROID'),
      count: getCountByPaymentPlace(appAndroid || []),
      visible: Boolean(getCountByPaymentPlace(appAndroid || [])),
    },
    {
      title: t('OrdersPage.APP_IOS'),
      count: getCountByPaymentPlace(appIOS || []),
      visible: Boolean(getCountByPaymentPlace(appIOS || [])),
    },
    {
      title: t('OrdersPage.OPERATOR'),
      count: getCountByPaymentPlace(operator || []),
      visible: Boolean(getCountByPaymentPlace(operator || [])),
    },
  ];

  const cityPrice = [
    {
      title: 'Алматы',
      price: `${getPriceByCity(
        ordersOnline.concat(ordersInPlace),
        'Алматы'
      )} ₸`,
      visible: Boolean(
        getPriceByCity(ordersOnline.concat(ordersInPlace), 'Алматы')
      ),
    },
    {
      title: 'Астана',
      price: `${getPriceByCity(
        ordersOnline.concat(ordersInPlace),
        'Астана'
      )} ₸`,
      visible: Boolean(
        getPriceByCity(ordersOnline.concat(ordersInPlace), 'Астана')
      ),
    },
    {
      title: 'Шымкент',
      price: `${getPriceByCity(
        ordersOnline.concat(ordersInPlace),
        'Шымкент'
      )} ₸`,
      visible: Boolean(
        getPriceByCity(ordersOnline.concat(ordersInPlace), 'Шымкент')
      ),
    },
    {
      title: 'Караганда',
      price: `${getPriceByCity(
        ordersOnline.concat(ordersInPlace),
        'Караганда'
      )} ₸`,
      visible: Boolean(
        getPriceByCity(ordersOnline.concat(ordersInPlace), 'Караганда')
      ),
    },
  ];

  const cityOrders = [
    {
      title: 'Алматы',
      cityOrdersOnline: getCityOrdersByCity(ordersOnline, 'Алматы'),
      cityOrdersInPlace: getCityOrdersByCity(ordersInPlace, 'Алматы'),
    },
    {
      title: 'Астана',
      cityOrdersOnline: getCityOrdersByCity(ordersOnline, 'Астана'),
      cityOrdersInPlace: getCityOrdersByCity(ordersInPlace, 'Астана'),
    },
    {
      title: 'Шымкент',
      cityOrdersOnline: getCityOrdersByCity(ordersOnline, 'Шымкент'),
      cityOrdersInPlace: getCityOrdersByCity(ordersInPlace, 'Шымкент'),
    },
    {
      title: 'Караганда',
      cityOrdersOnline: getCityOrdersByCity(ordersOnline, 'Караганда'),
      cityOrdersInPlace: getCityOrdersByCity(ordersInPlace, 'Караганда'),
    },
  ];
  const handleAccordionClickOnline = (index: number) => {
    if (activeIndexOnline === index) {
      setActiveIndexOnline(null);
    } else {
      setActiveIndexOnline(index);
    }
  };
  const handleAccordionClick = (index: number) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const sourceBlockClassname =
    userSources.find((el) => el.visible) && 'source-block';

  return (
    <Styled.Container>
      <Styled.Header>
        <Styled.TitleInfo>{day}</Styled.TitleInfo>
        {isMobile && (
          <Styled.ImgBlock onClick={() => setIsCollapsed(!isCollapsed)}>
            <img
              className={`headerIcon ${isCollapsed && 'headerIconCollapsed'}`}
              width={16}
              src={bottomArrow}
              alt="bottom arrow"
            />
          </Styled.ImgBlock>
        )}
      </Styled.Header>

      {(!isMobile || (isMobile && !isCollapsed)) && (
        <>
          {(userRole === UserRole.ADMIN ||
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.HEAD_OPERATOR) && (
            <div className={sourceBlockClassname}>
              {userSources.map(
                (el) =>
                  el.visible && (
                    <div key={el.title}>
                      <span className="source-title">{el.title}:</span>
                      <span className="source-count">{el.count}</span>
                    </div>
                  )
              )}
            </div>
          )}

          {(userRole === UserRole.ADMIN ||
            userRole === UserRole.OPERATOR ||
            userRole === UserRole.HEAD_OPERATOR) && (
            <div className="city-price-day">
              {cityPrice.map(
                (el) =>
                  el.visible && (
                    <div key={el.title}>
                      <span className="city-title">{el.title} </span>
                      <span className="city-price">{el.price}</span>
                    </div>
                  )
              )}
            </div>
          )}

          <div className="amount-block">
            <div className="title"> {t('OrdersPage.PAY_ONLINE')}</div>
            <div className="closed-amount">{amountsOnline}</div>
          </div>

          <div className="quantity-block">
            <div className="title">{t('OrdersPage.ALL_QUANTITY_ONLINE')}</div>
            <div className="closed-amount">
              {getCountByPaymentPlace(ordersOnline)}
            </div>
          </div>

          <div className="amount-container">
            <div className="flex-container">
              <div className="closed-order">
                {cityOrders.map((el, index) => (
                  <div key={el.title + el.cityOrdersOnline}>
                    <div
                      className="city-price-block"
                      onClick={() => handleAccordionClickOnline(index)}
                    >
                      <div>
                        <span className="city">{el.title}</span>
                        <span className="city-price">
                          {getPriceByCityAndPayment(el.cityOrdersOnline)} ₸
                        </span>
                      </div>
                      <div className="accordion-button">
                        {activeIndexOnline === index
                          ? 'Скрыть детали'
                          : 'Открыть'}
                      </div>
                    </div>
                    {activeIndexOnline === index &&
                      el.cityOrdersOnline.map((el) => (
                        <div className="network-block" key={el.source_code}>
                          <div className="network-code">{el.source_name}</div>
                          <div className="orders">
                            {el.orders.map((e) => (
                              <div className="order" key={e.order_id}>
                                №{e.order_id} <span>({e.sum} ₸)</span>
                              </div>
                            ))}
                          </div>
                          <div className="amount-orders">
                            {el.orders.length > 0 && (
                              <>
                                {t('OrdersPage.AMOUNT_ORDERS')}: <br />
                                <span>{el.total} ₸</span>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-container">
              <div className="amount-block">
                <div className="title">{t('OrdersPage.PAY_IN_PLACE')}</div>
                <div className="closed-amount">{amountsInPlace}</div>
              </div>
              <div className="quantity-block quantity-block-place">
                <div className="title">
                  {t('OrdersPage.ALL_QUANTITY_PLACE')}
                </div>
                <div className="closed-amount">
                  {getCountByPaymentPlace(ordersInPlace)}
                </div>
              </div>
              <div className="closed-order">
                {cityOrders.map((el, index) => (
                  <div key={el.title}>
                    <div
                      className="city-price-block"
                      onClick={() => handleAccordionClick(index)}
                    >
                      <div>
                        <span className="city">{el.title}</span>
                        <span className="city-price">
                          {getPriceByCityAndPayment(el.cityOrdersInPlace)} ₸
                        </span>
                      </div>
                      <div className="accordion-button">
                        {activeIndex === index ? 'Скрыть детали' : 'Открыть'}
                      </div>
                    </div>
                    {activeIndex === index &&
                      el.cityOrdersInPlace?.map((el, index: number) => (
                        <div className="network-block" key={el.source_code}>
                          <div className="network-code">{el.source_name}</div>
                          {/* @ts-ignore */}
                          <div className="orders">
                            {el.orders.map((e) => (
                              <div className="order" key={e.order_id}>
                                №{e.order_id} <span>({e.sum} ₸)</span>
                              </div>
                            ))}
                          </div>
                          {/* @ts-ignore */}
                          <div className="amount-orders">
                            {index === 0 && (
                              <>
                                {t('OrdersPage.AMOUNT_ORDERS')}: <br />
                              </>
                            )}
                            <span>{el.total} ₸</span>
                          </div>
                        </div>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </Styled.Container>
  );
};

export default InfoReportByDay;
