import { asyncActionsCreator } from '../../global/redux';
import * as constants from './constants';

export const getSalesProducts = asyncActionsCreator(
  constants.GET_SALES_PRODUCTS
);
export const updateSalesProduct = asyncActionsCreator(
  constants.UPDATE_SALES_PRODUCT
);

export const getProductsBySku = asyncActionsCreator(
  constants.GET_PRODUCTS_BY_SKU
);
export const updateCost = asyncActionsCreator(constants.UPDATE_COST);
export const deleteProduct = asyncActionsCreator(constants.DELETE_PRODUCT);
export const createNewSaleOrder = asyncActionsCreator(
  constants.CREATE_NEW_ORDER
);
